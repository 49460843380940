import React from "react";
import "./Plans.css";
import whiteTick from "../../assets/whiteTick.png";
import { plansData } from "../../data/plansData";

const Plans = () => {
  return (
    <div className="container-p" id="plans">
      <div className="blur plan-blur-1"></div>
      <div className="blur plan-blur-2"></div>
      <div className="header-p">
        <span className="storke-text">ready to start</span>
        <span>your journey</span>
        <span className="storke-text">now with us</span>
      </div>
      <div className="plans">
        {plansData.map((plans, i) => (
          <div className="plan" key={i}>
            {plans.icon}
            <span>{plans.name}</span>
            <span>${plans.price}</span>

            <div className="features">
              {plans.features.map((features, i) => (
                <div className="feature">
                  <img src={whiteTick} alt="" />
                  <span key={1}>{features}</span>
                </div>
              ))}
            </div>
            <div>
              <span>See More benefits ==></span>
            </div>
            <button className="btn">Join Now</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
